
import React, { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';

// @ts-ignore
import { Defs } from '@nivo/core';

import { ResponsiveLine } from '@nivo/line';
import { Card, Tag } from "antd";
import { area, curveMonotoneX } from 'd3-shape';
import moment from "moment";

// @ts-ignore
import styles from "./styles.module.scss";
import { useTracked } from "../../state";


function ProductionNumber() {
    const [state, dispatch] = useTracked();

    const production = (state.plant1Production + state.plant2Production)/1000;

    return (
        <Tag style={{ width: "100%", textAlign: "center", marginBottom: 12 }}><AnimatedNumber component="span" stepPrecision={1} value={production}
            style={{
                transition: '0.8s ease-out',
                fontWeight: 900,
                transitionProperty:
                    'background-color, color, opacity'
            }}
            formatValue={(n) => n.toFixed(1)}
            duration={800}
        /> MW</Tag>
    );
}

export interface IHydropowerProductionWidget {
}
function HydropowerProductionWidget(props: IHydropowerProductionWidget) {

    const [state, dispatch] = useTracked();
    const [realtimeChartData, setRealtimeChartData] = useState([]);
    const [productionDescription, setProductionDescription] = useState("");
    const [showData, setShowData] = useState(false);
    const [update, setUpdate] = useState(false);

    function getRndInteger(min, max) {
        return Math.floor(Math.random() * (max - min)) + min;
    }

    useEffect(() => {


        const customerdata = [];
        let i;
        for (i = 0; i < 29; i++) {
            customerdata.push({ timestamp: moment().subtract(i, 'hour'), production: getRndInteger(3, 6) * 100 });
        }

        const realtimeChartData = customerdata.map((data) => ({ x: moment(data.timestamp).toDate(), y: data.production ? data.production : null }));
        setRealtimeChartData(realtimeChartData);
        setTimeout(function () { setUpdate(!update) }, 1000);
    }, []);



    const AreaLayerHasData = ({ series, xScale, yScale, innerHeight }) => {
        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => Math.min(innerHeight, yScale(d.data.y - d.data.y)))
            .y1(d => yScale(d.data.y ? d.data.y : 0))
            .curve(curveMonotoneX);

        return (
            <>
                <Defs
                    defs={[
                        {
                            id: 'pattern',
                            type: 'patternLines',
                            background: 'transparent',
                            color: '#3daff7',
                            lineWidth: 1,
                            spacing: 6,
                            rotation: -45,
                        },
                    ]}
                />
                <path
                    d={areaGenerator(series[0].data)}
                    fill="url(#pattern)"
                    fillOpacity={0.2}
                    stroke="#3daff7"
                    strokeWidth={0}
                />
            </>
        );
    };

    const AreaLayerMissingData = ({ series, xScale, yScale, innerHeight }) => {
        const areaGenerator = area()
            .x(d => xScale(d.data.x))
            .y0(d => Math.min(innerHeight, yScale(d.data.y - d.data.y)))
            .y1(d => yScale(d.data.y ? 0 : 1000))
            .curve(curveMonotoneX);

        return (
            <>
                <Defs
                    defs={[
                        {
                            id: 'pattern1',
                            type: 'patternLines',
                            background: 'transparent',
                            color: '#AFAFAF',
                            lineWidth: 1,
                            spacing: 6,
                            rotation: -45,
                        },
                    ]}
                />
                <path
                    d={areaGenerator(series[0].data)}
                    fill="url(#pattern1)"
                    fillOpacity={0.3}
                    stroke="#AFAFAF"
                    strokeWidth={0}
                />
            </>
        );
    };

    const styleById = {
        default: {
            strokeDasharray: '2, 4',
            strokeWidth: 2,
            opacity: 0.4
        },
    }

    const DashedLine = ({ series, lineGenerator, xScale, yScale }) => {
        return series.map(({ id, data, color }) => (
            <path
                key={id}
                d={lineGenerator(
                    data.map(d => {
                        return {
                            x: xScale(d.data.x),
                            y: yScale(d.data.y)
                        }
                    }))}
                fill="none"
                stroke={color}
                style={styleById[id] || styleById.default}
            />
        ));
    };

    const MyResponsiveLine = (
        // @ts-ignore
        <ResponsiveLine
            data={[
                { id: 'A', data: realtimeChartData }
            ]}
            enableGridX={false}
            enableGridY={false}
            margin={{ top: 6, right: 6, bottom: 24, left: 28 }}
            xScale={{
                type: 'time',
                format: 'native',
                useUTC: false,
                precision: 'minute',
            }}
            xFormat="time:%Y-%m-%d"
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            enableArea={true}
            axisBottom={{
                format: '%H',
                tickValues: 'every 4 hours',
                legend: '',
                legendOffset: -12,
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickValues: 4,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            enablePoints={false}
            colors="#4BB2E7"
            pointSize={10}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            theme={{
                // @ts-ignore
                textColor: '#BDBDBD',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                }
            }}
            layers={[
                'grid',
                'markers',
                'areas',
                AreaLayerMissingData,
                'lines',
                'slices',
                'axes',
                'points',
                'legends',
            ]}
            legends={[]}
        />);

    return (
        <Card title={"Produksjon"} bordered={false} extra={<ProductionNumber />} bodyStyle={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 16, paddingRight: 16 }} style={{ width: "100%", height: 150, backgroundColor: "#101926", color: "#FFF" }} headStyle={{ height: 40, color: "#FFF", marginBottom: 6, fontWeight: 300, borderBottomWidth: 0, paddingLeft: 16, paddingRight: 16 }} className={styles.shadow}>
            <div style={{ width: "100%", height: 96, paddingBottom: 0 }}>
                {MyResponsiveLine}
            </div>
        </Card>
    );
}

export default HydropowerProductionWidget;
