import React, { useEffect, useState } from "react";

import { LeftOutlined, MenuOutlined, DotChartOutlined, DashboardOutlined, PieChartOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Select, Menu, Dropdown, InputNumber, Form, Divider, Tag } from "antd";

import AnimatedNumber from 'react-animated-number';
import { ResponsiveScatterPlot } from '@nivo/scatterplot';

import { navigate } from '@reach/router';

import { useDispatch, useTrackedState } from '../../state';

import EventsCalendarWidget from '../EventsCalendarWidget';
import PlantMenu from "../General/PlantMenu";
import moment from "moment";
import BigDivider from "../General/Divider";


interface HydropowerPlantEconomicsState {
    kraftpris: number;
    normalproduksjon: number;
    installerteffekt: number;
    brukstid: number;
}

const { Option } = Select;

// @ts-ignore
//import styles from "./plantoverview.module.scss";

export interface IHydropowerPlantEconomics {
    path?: string;
}

function HydropowerPlantEconomics(props: IHydropowerPlantEconomics) {

    const dispatch = useDispatch();
    const state = useTrackedState();

    const [todaysProductionTotal, setTodaysProductionTotal] = useState(0);
    const [todaysProductionHour, setTodaysProductionHour] = useState(0);
    const [producedStartOfDay, setProducedStartOfDay] = useState(0);

    let todaysProduction = 0;

    useEffect(() => {

        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        const interval = setInterval(() => {
            const _new = todaysProduction + getRndInteger(1, 5);
            todaysProduction = _new;
            setTodaysProductionTotal(_new);
        }, 1500);
        return () => clearInterval(interval);
    }, []);

    const [localState, setLocalState] = useState<HydropowerPlantEconomicsState>({ kraftpris: 0.3, installerteffekt: 800, normalproduksjon: 2, brukstid: 2500 });


    const onFinish = values => {
        // console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        // console.log('Failed:', errorInfo);
    };

    const onChange = (values, e) => {

        const brukstid = ((values.normalproduksjon ? values.normalproduksjon * 1000000 : localState.normalproduksjon * 1000000) / (values.installerteffekt ? values.installerteffekt : localState.installerteffekt)).toFixed(0);
        console.log(values);
        console.log({ ...localState, ...values });
        setLocalState({ ...localState, ...values, brukstid });
    };

    return (
        <Row style={{ margin: 24, marginTop: 88 }} gutter={[12, 12]}>
            <Col xs={24} md={8}>
                <Card title={"Produksjon"}>
                    <div style={{ fontSize: 12, opacity: 0.9 }}>Så langt i dag (kr)</div>
                    <AnimatedNumber component="div" stepPrecision={1} value={(todaysProductionTotal * localState.kraftpris)}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 44,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(0) + ",-"}
                        duration={900}
                    />
                    <Tag>{(todaysProductionTotal * localState.kraftpris / 24).toFixed(0)},- kr/h</Tag>
                    <Tag>{todaysProductionTotal} kWh</Tag>
                </Card>
            </Col>
            <Col xs={24} md={8}>
                <Card title={"Nedtidskostnad"} extra={<Tag color="grey">Normal</Tag>}>
                    <div style={{ fontSize: 12, opacity: 0.9 }}>Per døgn (kr)</div>
                    <AnimatedNumber component="div" stepPrecision={1} value={(((localState.installerteffekt * localState.brukstid) / 8760) * localState.kraftpris * 24)}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 44,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(0) + ",-"}
                        duration={900}
                    />
                    <Tag>{(((localState.installerteffekt * localState.brukstid) / 8760) * localState.kraftpris).toFixed(0)},- kr/h</Tag>
                </Card>
            </Col>
            <Col xs={24} md={8}>
                <Card title={"Nedetidskostnad"} extra={<Tag color="red">Høy</Tag>}>
                    <div style={{ fontSize: 12, opacity: 0.9 }}>Per døgn (kr)</div>
                    <AnimatedNumber component="div" stepPrecision={1} value={((localState.installerteffekt * localState.kraftpris) * 24)}
                        style={{
                            transition: '0.8s ease-out',
                            fontSize: 44,
                            transitionProperty:
                                'background-color, color, opacity'
                        }}
                        formatValue={(n) => n.toFixed(0) + ",-"}
                        duration={900}
                    />
                    <Tag>{(localState.installerteffekt * localState.kraftpris).toFixed(0)},- kr/h</Tag>
                </Card>
            </Col>
            <Col xs={24}>
                <div style={{ margin: "0 auto" }}>
                    <Card>
                    <Form
                        layout="inline"
                        name="basic"
                        initialValues={{ remember: true }}
                        onValuesChange={onChange}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        hideRequiredMark={true}
                    >
                        <Form.Item
                            style={{ marginRight: 12 }}
                            label="Kraftpris (kr/kWh)"
                            name="kraftpris"
                            rules={[{ required: true, message: 'Du må skrive inn en kraftpris!' }]}
                            initialValue={0.3}
                        >
                            <InputNumber size="large" min={0} step={0.1} max={100000} />
                        </Form.Item>
                        <Form.Item
                            style={{ marginRight: 12 }}
                            label="Normalproduksjon (GWh)"
                            name="normalproduksjon"
                            rules={[{ required: true, message: 'Du må skrive inn normalproduksjon!' }]}
                            initialValue={2}
                        >
                            <InputNumber size="large" step={0.1} min={0.1} max={100000} />
                        </Form.Item>
                        <Form.Item
                            style={{ marginRight: 0 }}
                            label="Installert effekt (kW)"
                            name="installerteffekt"
                            rules={[{ required: true, message: 'Du må skrive inn installert effekt!' }]}
                            initialValue={800}
                        >
                            <InputNumber size="large" min={1} max={100000000} />
                        </Form.Item>
                    </Form>
                    </Card>
                </div>
            </Col>
        </Row>
    );
}

export default HydropowerPlantEconomics;
