import { Col, Row, Tag } from "antd";
import React from "react";
import { useTrackedState } from "../../state";

import AnimatedNumber from 'react-animated-number';
import { Circle } from 'rc-progress';
import VoltageWidget from "../VoltageWidget";

// <PlantProductionGauge />

// <VoltageWidget items={voltageSensors} />

// {renderStates()}

// {renderSensors()}

function PlantProductionGauge() {

    const state = useTrackedState();

    const _latest = state.plant1Production;

    return (
        <>
            <div style={{ width: 250, height: 200 }}>
                <Circle style={{ width: 250, height: 230 }}
                    percent={[_latest ? (_latest / 1000 * 100) : 0, 1]}
                    gapDegree={90}
                    gapPosition="bottom"
                    strokeWidth={6}
                    strokeLinecap="butt"
                    strokeColor={["#1C384C", "#4BB2E7"]}
                />
                <div style={{ marginTop: -110, marginLeft: 58, }}>
                    <Tag style={{ width: 130, fontSize: 40, padding: 12, textAlign: "center" }}>
                        <AnimatedNumber component="span" stepPrecision={1} value={_latest ? _latest : 0}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 40,
                                fontWeight: 900,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                            formatValue={(n) => n.toFixed(1)}
                            duration={800}
                        />
                    </Tag></div>
            </div>
            <div style={{ textAlign: "center" }}>PRODUKSJON (KW)</div>
        </>
    );
}

function HydroPowerPlantOverview() {
    return (
        <Row>
            <Col xs={24} style={{ padding: 18 }}>
                <div style={{ textAlign: "center", fontSize: 28, color: "#FFF" }}>Kraftverk #1</div>
                <div style={{ textAlign: "center", fontSize: 12, color: "#FFF", opacity: 0.8, textTransform: "uppercase" }}>EFFEKT: 1MW, ÅRSPRODUKSJON: 3.1GWH</div>
            </Col>
            <Col xs={24}>
                <div style={{ margin: "0 auto", color: "#FFF", padding: 24, }}>
                    <Row gutter={[12, 12]} align="middle" style={{ paddingTop: 24 }}>
                        <Col xs={24}  >
                            <div style={{ margin: "0 auto", width: 250, marginBottom: 48 }}>
                                <PlantProductionGauge />
                            </div>
                        </Col>
                        <Col xs={24}>
                            <div style={{ margin: "0 auto", width: 280, marginBottom: 48 }}>
                                <VoltageWidget />
                            </div>
                        </Col>

                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default HydroPowerPlantOverview;