
import React, { useEffect, useState } from "react";

import { ResponsiveBar } from '@nivo/bar';
import { Card, Tag } from "antd";
import moment from "moment";

import { useTracked } from "../../state";

// @ts-ignore
import styles from "./eventswidget.module.scss";


export interface IEventsWidget {

}
function EventsWidget(props: IEventsWidget) {

    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        const customereventscount = [];
        let i;
        for (i = 0; i < 29; i++) {
            customereventscount.push({timestamp: moment().subtract(i, 'h'), count: Math.floor(Math.random() * 20)});
        }

        const _chartData = customereventscount.map((data) => ({ timestamp: moment(data.timestamp).format("HH"), count: data.count }));
        setChartData(_chartData);
    }, []);

    const CustomTick = (tick) => {
        if (["00", "04", "08", "12", "16", "20"].includes(tick.value)) {
            return (
                <g transform={`translate(${tick.x},${tick.y + 20})`}>

                    <line stroke="#FFF" strokeWidth={1} y1={-19} y2={-14} />
                    <text
                        textAnchor="middle"
                        dominantBaseline="top"
                        style={{
                            fill: '#BDBDBD',
                            fontSize: 11,
                        }}
                    >
                        {tick.value}
                    </text>
                </g>
            );
        } else {
            return null;
        }
    };

    const CustomBarComponent = ({ x, y, width, height, color }) => (
        <g transform={`translate(${x + 1},${y + 1})`}>
            <line stroke={color} strokeWidth={6} y1={width} y2={height} />
            <line stroke="#4BB2E7" strokeWidth={6} y1={width + 3} y2={2} />
        </g>
    );

    const MyResponsiveBar = () => (
        <ResponsiveBar
            data={chartData}
            keys={['count']}
            indexBy="timestamp"
            enableGridX={false}
            enableGridY={false}
            margin={{ top: 6, right: 6, bottom: 24, left: 24 }}
            padding={0.8}
            borderWidth={1}
            colors="#1C384C"
            borderColor="#4BB2E7"
            axisTop={null}
            axisRight={null}
            barComponent={CustomBarComponent}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 1,
                legend: '',
                legendPosition: 'middle',
                legendOffset: 0,
                renderTick: CustomTick
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                tickValues: 4,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40
            }}
            /*markers={[
                {
                    axis: 'y',
                    value: 0,
                    lineStyle: { stroke: '#2C2C2C', strokeWidth: 1 },
                },
            ]}*/
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            legends={[]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={false}
            theme={{
                // @ts-ignore
                textColor: '#BDBDBD',
                axis: {
                    ticks: {
                        line: {
                            stroke: '#FFF',
                            strokeWidth: 1
                        },
                        text: {
                            fontSize: 11
                        }
                    },
                }
            }}
        />);

    return (
        <Card title={"Hendelser"} bordered={false} extra={<Tag style={{ marginBottom: 12 }}>{chartData.reduce((a, b) => a + b.count, 0)}</Tag>} bodyStyle={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 16, paddingRight: 16 }} style={{ width: "100%", height: 150, backgroundColor: "#101926", color: "#FFF" }} headStyle={{ height: 40, color: "#FFF", marginBottom: 6, fontWeight: 300, borderBottomWidth: 0, paddingLeft: 16, paddingRight: 16 }} className={styles.shadow}>
            <div style={{ width: "100%", height: 96, paddingBottom: 0 }}>
                {MyResponsiveBar()}
            </div>
        </Card>
    );
}

export default EventsWidget;
