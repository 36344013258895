import React, { useEffect, useState } from 'react';
import Login from '../components/Login';

import { Helmet } from "react-helmet";
import { navigate } from '@reach/router';
import { useTracked, ActionTypes } from '../state';
import { validateToken } from '../state/actions';
import { Button, Col, Layout, Row } from 'antd';
import { BellOutlined, CalendarOutlined, DotChartOutlined, FundOutlined, LoginOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import Typist from 'react-typist';



import HydropowerPlantEconomics from '../components/HydropowerPlantEconomics';

import './defaults.css';
import Map from '../components/Map';

const { Header, Footer, Sider, Content } = Layout;

// @ts-ignore
import DeviceFrame from '../../static/device.inline.svg';

// @ts-ignore
import LeftShadowDeviceFrame from '../../static/leftshadow.inline.svg';

// @ts-ignore
import RightShadowDeviceFrame from '../../static/rightshadow.inline.svg';

// @ts-ignore
import Concave from '../../static/concave.inline.svg';

// @ts-ignore
import Convex from '../../static/convex.inline.svg';

// @ts-ignore
import Analytics from '../../static/analytics.png';

// @ts-ignore
import AnalyticsCentered from '../../static/analyticsCentered.png';


// @ts-ignore
import styles from './index.module.scss';

// @ts-ignore
import deviceframestyles from './deviceframe.module.scss';
import HydroPowerPlantOverview from '../components/HydroPowerPlantOverview';

const bookingPage = "https://outlook.office365.com/owa/calendar/kinetik1@convivial.no/bookings/";

function DeviceSimulator() {
    return (

        <div style={{ width: "100%", overflow: "hidden" }}>
            <div style={{ maxWidth: 900, minWidth: 640, margin: "0 auto", padding: 24, paddingBottom: 180 }}>
                <div style={{ paddingBottom: "75%", position: "relative", borderRadius: 72, boxShadow: "34px 34px 55px #adadad, -34px -34px 55px #ffffff", background: "linear-gradient(to bottom right, #636363, #313131)" }}>
                    <div style={{ background: "black", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, borderRadius: 60, margin: 12, zIndex: 99 }}>
                        <Map />
                    </div>
                    <LeftShadowDeviceFrame style={{ position: "absolute", bottom: -312, left: 0, zIndex: 0, display: "inline-block" }} />
                    <RightShadowDeviceFrame style={{ position: "absolute", top: 0, right: -248, zIndex: 0, display: "inline-block" }} />
                </div>
            </div>
        </div>
    );
}

function PhoneSimulator() {
    return (
        <div style={{ width: "100%", minHeight: 520, overflow: "hidden", position: "relative" }}>
            <div style={{ position: "absolute", left: "50%", top: "50%", transform: "translate3d(-50%, -50%, 0) scale(0.80) rotateX(58deg) rotateY(0deg) rotateZ(45deg)" }}>
                <div style={{ height: 812, width: 375, position: "relative", transform: "translate3d(0, 0, 0)", borderRadius: 72, boxShadow: "90px 90px 90px rgba(0, 0, 0, .39)", background: "linear-gradient(to bottom right, #636363, #313131)" }}>
                    <div style={{ background: "#16191D", position: "absolute", top: 0, left: 0, right: 0, bottom: 0, borderRadius: 60, margin: 18, zIndex: 99, overflow: "hidden" }} className={styles.phoneScroll}>
                        <HydroPowerPlantOverview />
                    </div>
                </div>
            </div>
        </div>
    );
}

function Logo() {
    return (
        <div style={{ margin: "0 auto", color: "#4BB2E7", fontSize: 24, fontWeight: 700, borderColor: "#E2E2E2", borderRadius: 6, borderWidth: 2, borderStyle: "solid", width: 85, height: 34, paddingTop: 3, lineHeight: 1, textAlign: "center" }}>kinetik</div>
    );
}

function LogoSmall() {
    return (
        <div style={{ display: "inline-block", margin: "0 auto", color: "#4BB2E7", fontSize: 16, fontWeight: 700, borderColor: "#E2E2E2", borderRadius: 6, borderWidth: 2, borderStyle: "solid", width: 65, height: 28, paddingTop: 3, lineHeight: 1, textAlign: "center" }}>kinetik</div>
    );
}

function Dot() {
    return (
        <span style={{ borderRadius: "50%", height: 6, width: 6, backgroundColor: "#4BB2E7", display: "inline-block", marginBottom: 3, marginRight: 6 }} />
    );
}


/*
 <Col xs={24}>
                                    <div style={{ maxWidth: 1034, width: "100%", minHeight: 727, height: "auto", overflow: "hidden", position: "relative" }} className={styles.monitoringImageContainer}><img style={{ position: "absolute", marginLeft: "50%", transform: "translateX(-50%)" }} src={MobileContent} /></div>
                                </Col>
*/
function Index({ children, location }) {

    const [state, dispatch] = useTracked();
    const [typeLoop, setTypeLoop] = useState<number>(0);

    useEffect(() => {
        dispatch({ type: ActionTypes.SET_LOCATION, location: location.pathname });
    }, [location]);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>kinetik</title>
                <link rel="canonical" href="https://kinetik.no" />
                <meta name="description" content="Sanntidsovervåking, analyse og økonomi av vannkraftverk" />
                <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&family=Titillium+Web:wght@400;700&display=swap" rel="stylesheet" />
                <script async defer data-domain="kinetik.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <Layout style={{ backgroundColor: "#FFF", minWidth: 320 }}>
                <Header style={{ backgroundColor: "#FFF", paddingTop: 12, height: 78, padding: 12 }}>
                    <Row align="middle" style={{ margin: "0 auto", maxWidth: 1200 }}>
                        <Col flex="100">
                            <a href="https://convivial.no" target="_blank"><Button style={{ marginRight: 0, marginLeft: "auto", color: "#20252C" }} type="link" size="large">Convivial</Button></a>
                        </Col>
                        <Col flex="auto">
                            <Logo />
                        </Col>
                        <Col flex="100">
                            <a href="https://console.kinetik.no" target="_blank"><Button style={{ marginRight: 0, marginLeft: "auto", color: "#20252C", display: "block" }} type="link" size="large">Logg inn</Button></a>
                        </Col>
                    </Row>
                </Header>
                <Content style={{ backgroundColor: "#FFF" }}>
                    <div className={styles.headline}>
                        <div style={{ display: "inline-block" }}>Sanntids</div>
                        <Typist cursor={{ show: false }} className={styles.typist} key={typeLoop} onTypingDone={() => setTypeLoop(typeLoop + 1)} style={{ display: "inline-block" }}>
                            <span style={{ fontWeight: 700 }}>overvåking</span>
                            <Typist.Backspace count={10} delay={3000} />
                            <span style={{ fontWeight: 700 }}>analyse</span>
                            <Typist.Backspace count={7} delay={3000} />
                            <span style={{ fontWeight: 700 }}>økonomi</span>
                            <Typist.Backspace count={8} delay={3000} />
                        </Typist>
                        <br />av vannkraftverk
                    </div>
                    <DeviceSimulator />

                    <div style={{ width: "100%", maxWidth: 650 }} className={styles.intro}>
                        <Row gutter={[0, 24]}>
                            <Col xs={24}>
                                <div style={{ fontSize: 18, textAlign: "center" }}><LogoSmall /> er utviklet i Drammen for store og små vannkraftverk i hele&nbsp;verden.</div>
                            </Col>
                            <Col xs={24}>
                                <a href={bookingPage} target="_blank"><Button style={{ fontSize: 18, height: 40, width: 200, margin: "0 auto", display: "block" }} type="primary" icon={<CalendarOutlined />}>Book en demo</Button></a>
                            </Col>
                        </Row>
                    </div>


                    <Convex />
                    <div style={{ backgroundColor: "#F8F8F8" }} className={styles.monitoringContainer}>
                        <div style={{ width: "100%", maxWidth: 1200 }} className={styles.monitoringSubContainer}>
                            <Row gutter={[0, 0]} style={{ marginTop: -150 }}>
                                <Col xs={24}>
                                    <div className={styles.headline} style={{ fontWeight: 700, color: "#4BB2E7", marginBottom: 24 }}>
                                        <BellOutlined style={{ fontSize: 48, color: "#4BB2E7" }} /><br />Overvåking
                                    </div>
                                </Col>
                                <Col xs={24} >
                                    <div className={styles.subheadline} style={{ paddingLeft: 24, paddingRight: 24 }}>
                                        Har du full kontroll over&nbsp;kraftverkene?
                                    </div>
                                </Col>

                                <Col xs={24}>
                                    <PhoneSimulator />
                                </Col>
                            </Row>
                            <Row gutter={[0, 0]} style={{ marginTop: -50 }}>
                                <Col xs={24} md={12}>
                                    <div style={{ fontSize: 18, marginTop: 48, maxWidth: 300 }} className={styles.monitoringFactBox}>
                                        <div style={{ marginBottom: 12 }}>Med <LogoSmall /> får du</div>
                                        <div style={{ fontSize: 32, marginBottom: 24 }}>Overvåking</div>
                                        <Dot />Egendefinerte terskelverdier.<br />
                                        <Dot />Velge ulike varslingstyper.<br />
                                        <Dot />Responsivt design<br />
                                        <a href={bookingPage} target="_blank"><Button style={{ fontSize: 18, height: 40, marginTop: 12 }} type="primary" icon={<CalendarOutlined />}>Book en demo</Button></a>
                                    </div>
                                </Col>
                                <Col xs={24} md={12}>
                                    <div style={{ fontSize: 18, marginTop: 48 }} className={styles.monitoringFactBox2}>
                                        Kinetik gir dere unik innsikt i de dataene som samles inn i kraftverket, i sanntid. Dere kan følge med på hvor mye som
                                        produseres til enhver tid, hvor mange grader det er i styrelageret eller hvilke hendelser som har vært den siste
                                        tiden, for å nevne noe.<br /><br />

                                        Med Kinetik vil driftspersonell få en kjempefordel i daglig drift, hvor tilstanden i kraftverket kan sjekkes på
                                        mobiltelefonen fremfor å måtte ta den ekstra turen til kraftverket.<br /><br />

                                        Med Kinetik får dere oversikt over kraftverkene deres, rett i lomma.
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <Concave style={{ transform: "rotate(180deg)" }} />
                    <div style={{ margin: "0 auto", width: "100%", maxWidth: 1200 }}>

                        <Row gutter={[0, 0]} style={{}}>
                            <Col xs={24}>
                                <div style={{ marginTop: 48, marginBottom: 24, color: "#4BB2E7" }} className={styles.headline}>
                                    <DotChartOutlined style={{ fontSize: 48, color: "#4BB2E7" }} /><br />Analyse
                                </div>
                            </Col>
                            <Col xs={24} >
                                <div style={{ marginBottom: 48, color: "#273E4C", paddingLeft: 24, paddingRight: 24 }} className={styles.subheadline}>
                                    Fanger dere opp trender i&nbsp;kraftverkene?
                                </div>
                            </Col>
                        </Row>

                        <Row className={styles.analyticsFactsContainer}>
                            <Col xs={24} lg={0}>
                                <img style={{ maxWidth: "100%", height: "auto", width: "auto", maxHeight: 727, margin: "0 auto", display: "block" }} src={AnalyticsCentered} />
                            </Col>
                            <Col xs={0} lg={12}>
                                <img width={700} src={Analytics} />
                            </Col>
                            <Col xs={24} lg={12} className={styles.analyticsFactContainer}>
                                <Row gutter={[0, 0]} style={{ marginTop: 48 }}>
                                    <Col xs={{ span: 24, order: 2 }} md={{ span: 12, order: 2 }} lg={{ span: 24, order: 1 }}>
                                        <div style={{ fontSize: 18, width: "100%" }} className={styles.analyticsFactBox1}>
                                            Kinetik bruker maskinlæring og kunstig intelligens for å gi dere
                                            prediktive analyser, som gir indikasjon på hvordan tilstanden er, opp
                                            mot slik den burde være. Samsvarer disse verdiene eller finnes det
                                            avvik? Med Kinetik får du svar på dette slik at dere kan fange opp
                                            avvik og ta kvalifiserte beslutninger rundt videre drift.<br /><br />

                                            Det at ting tar lenger tid for hver gang en hendelse skjer, kan i
                                            verste fall ende med et havari. Vi fanger opp disse trendene slik at
                                            dere kan gjennomføre nødvendig vedlikehold.<br /><br />

                                            Med Kinetik får dere ekstra hjelp til å fange opp og forutse uønsket driftsstans.<br /><br />
                                        </div>
                                    </Col>
                                    <Col xs={{ span: 24, order: 1 }} md={{ span: 12, order: 1 }} lg={{ span: 24, order: 2 }}>
                                        <div style={{ fontSize: 18, maxWidth: 300 }} className={styles.analyticsFactBox2}>
                                            <div style={{ marginBottom: 12 }}>Med <LogoSmall /> får du</div>
                                            <div style={{ fontSize: 32, marginBottom: 24 }}>Analyse</div>
                                            <Dot />Oppstartstid (Utvikling over tid)<br />
                                            <Dot />Temperaturavvik (Styrelager)<br />
                                            <Dot />Vibrasjonsmålinger (Effekt og fall)<br />
                                            <a href={bookingPage} target="_blank"><Button style={{ fontSize: 18, height: 40, marginTop: 12 }} type="primary" icon={<CalendarOutlined />} >Book en demo</Button></a>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div style={{ width: "100%", maxWidth: 1200 }} className={styles.economicsFactContainer}>
                        <Row gutter={[0, 0]} style={{}}>
                            <Col xs={24}>
                                <div style={{ marginTop: 48, marginBottom: 24, color: "#4BB2E7" }} className={styles.headline}>
                                    <FundOutlined style={{ fontSize: 48, color: "#4BB2E7" }} /><br />Økonomi
                                </div>
                            </Col>
                            <Col xs={24} >
                                <div style={{ marginBottom: 0, color: "#273E4C", paddingLeft: 24, paddingRight: 24 }} className={styles.subheadline}>
                                    Er det lønnsomt å produsere strøm&nbsp;nå?
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 48 }}>
                            <Col xs={24}>
                                <div style={{ maxWidth: 900, margin: "0 auto" }}><HydropowerPlantEconomics /></div>
                            </Col>

                        </Row>
                        <Row gutter={[0, 0]} style={{ marginTop: 48 }}>
                            <Col xs={24} md={12}>
                                <div style={{ fontSize: 18, maxWidth: 300 }} className={styles.economicsFactBox1}>
                                    <div style={{ marginBottom: 12 }}>Med <LogoSmall /> får du</div>
                                    <div style={{ fontSize: 32, marginBottom: 24 }}>Økonomi</div>
                                    <Dot />Egendefinerte beregninger<br />
                                    <Dot />Se nedetidskostnader<br />
                                    <a href={bookingPage} target="_blank"><Button style={{ fontSize: 18, height: 40, marginTop: 12 }} type="primary" icon={<CalendarOutlined />}>Book en demo</Button></a>
                                </div>
                            </Col>
                            <Col xs={24} md={12} >
                                <div style={{ fontSize: 18 }} className={styles.economicsFactBox2}>
                                    Er det lønnsomt å produsere strøm nå, eller bør man utføre vedlikeholdet i disse dager?<br /><br />
                                    Kinetik gir deg svar på hvilke gevinster og evt. utgifter man får ved produksjon eller produksjonsstopp, basert på priser du selv velger å simulere på. <br /><br />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Content>
                <Footer style={{ backgroundColor: "#16191D", marginTop: 148, color: "#FFF" }}>
                    <Row style={{ maxWidth: 1200, margin: "0 auto" }}>
                        <Col xs={24} md={12}>


                            <div style={{ fontSize: 18, marginTop: 48, maxWidth: 300 }} className={styles.footerFactBox1}>
                                <div style={{ marginBottom: 32 }}><LogoSmall /></div>
                                <PhoneOutlined />  +47 980 56 854<br />
                                <MailOutlined />  hei@kinetik.no<br />

                            </div>
                        </Col>
                        <Col xs={24} md={12}>

                            <div style={{ fontSize: 18, marginTop: 48, maxWidth: 350 }}>
                                <Dot />Vi tar gjerne en prat og svarer på de spørsmålene du måtte ha.<br /><br />
                                <Dot />Ønsker du en demo av produktet, tar vi gjerne en gjennomgang fysisk eller digitalt.<br /><br />
                            </div>
                        </Col>
                    </Row>
                </Footer>
            </Layout>

        </>
    );
}

export default Index;
