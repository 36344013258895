import React, { useEffect, useState } from "react";
import { useTracked } from "../../state";

import _ from "lodash";

import { ResponsiveRadar } from '@nivo/radar';

// @ts-ignore
import styles from "./voltagewidget.module.scss";

function VoltageWidget() {

    const [state, dispatch] = useTracked();
    const [data, setData] = useState([{ "name": "L1-L2", "U": 615, "UBB": 616 }, { "name": "L3-L1", "U": 615, "UBB": 616 }, { "name": "L2-L3", "U": 615, "UBB": 616 }]);
    const [keys, setKeys] = useState(["U", "UBB"]);

    const LabelComponent = ({ id, anchor }) => (
        <g transform={`translate(${anchor === 'end' ? -44 : anchor === 'middle' ? 14 : 14}, ${anchor === 'end' ? 4 : anchor === 'middle' ? 4 : 4})`}>
            <text fill={"#B8B8B8"} fontSize={12}>{id}</text>
        </g>
    );

    const MyResponsiveRadar = () => (
        <ResponsiveRadar
            // @ts-ignore
            gridLabel={LabelComponent}
            data={data}
            keys={keys}
            indexBy="name"
            maxValue="auto"
            margin={{ top: 24, right: 0, bottom: 0, left: 0 }}
            curve="linearClosed"
            borderWidth={2}
            borderColor={{ from: 'color' }}
            gridLevels={6}
            gridShape="linear"
            gridLabelOffset={0}
            enableDots={true}
            dotSize={10}
            colors={["#7DE74B", "#4BB2E7"]}
            dotColor={{ theme: 'background' }}
            dotBorderWidth={2}
            dotBorderColor={{ from: 'color' }}
            enableDotLabel={true}
            dotLabelFormat={(v) => <>{v}V</>}
            dotLabelYOffset={-12}
            fillOpacity={0.1}
            blendMode="multiply"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={true}
            legends={[
                {
                    textColor: "#FFF",
                    anchor: 'top-left',
                    direction: 'column',
                    translateX: 40,
                    translateY: 0,
                    itemWidth: 40,
                    itemHeight: 20,
                    itemTextColor: '#FFF',
                    symbolSize: 10,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#FFF'
                            }
                        }
                    ]
                }
            ]}
            theme={{
                textColor: "#FFF",
                dots: {
                    text: {
                        fontSize: 12,
                        fill: 'rgba(255,255,255,.9)'
                    }
                },
                tooltip: {
                    container: {
                        background: '#2d374d',
                        color: 'inherit',
                        boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
                        fontFamily: "monospace"
                    }
                },
                labels: {
                    text: {
                        color: "#FFF"
                    }
                },
                crosshair: {
                    line: {
                        stroke: "rgba(255,255,255,1)",
                        strokeWidth: 2,
                        // strokeOpacity: 0.75,
                        strokeDasharray: "0"
                    },
                    staticLine: {
                        stroke: "rgba(255,255,255,.3)",
                        strokeWidth: 2,
                        // strokeOpacity: 0.75,
                        strokeDasharray: "0"
                    }
                }
            }}
        />
    );

    // {MyResponsiveRadar()}

    return (
        <div style={{ width: 280, height: 240 }}>
            {MyResponsiveRadar()}
            <div style={{ textAlign: "center", marginTop: -32 }}>SPENNING (V)</div>
        </div>
    );
}

export default VoltageWidget;
