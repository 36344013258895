
import { navigate } from "@reach/router";
import React, { useEffect, useState } from "react";
import AnimatedNumber from 'react-animated-number';
import { Sparklines, SparklinesLine } from 'react-sparklines';
import SparklinesSpot from "../General/SparklinesSpot";

import { Tag } from "antd";
import { ActionTypes, IHydropowerPlant, useTracked } from '../../state';

// @ts-ignore
import styles from "./styles.module.scss";

export interface IHydropowerPlantMarker {
    hydropowerPlant?: IHydropowerPlant;
    x?: number;
    y?: number;
}

function HydropowerPlantMarker(props: IHydropowerPlantMarker) {

    //const [latestRealtimeData, setLatestRealtimeData] = useState<KinetikRealtimePlantDataMessage>({ kinetikData: {} });
    const [state, dispatch] = useTracked();
    const [realtimeChartData, setRealtimeChartData] = useState([]);
    const [productionDescription, setProductionDescription] = useState("");
    const [showData, setShowData] = useState(true);


    const [seconds, setSeconds] = useState(0);
    const [production, setProduction] = useState(0);

    useEffect(() => {

        function getRndInteger(min, max) {
            return Math.floor(Math.random() * (max - min +0.1)) + min;
        }

        const interval = setInterval(() => {
            setSeconds(seconds => seconds + 1);
            if (props.hydropowerPlant.name === "Kraftverk #1") {
                const _v1 = getRndInteger(800, 900);
                setProductionDescription("KW");
                setProduction(_v1);
                realtimeChartData.push(_v1);
                setRealtimeChartData(realtimeChartData);
                dispatch({ type: ActionTypes.SET_POWERPLANT1PRODUCTION, production: _v1 });
            }
            if (props.hydropowerPlant.name === "Kraftverk #2") {
                const _v2 = getRndInteger(2800, 3000);
                setProductionDescription("MW");
                setProduction(_v2/1000);
                realtimeChartData.push(_v2/1000);
                setRealtimeChartData(realtimeChartData);
                dispatch({ type: ActionTypes.SET_POWERPLANT2PRODUCTION, production: _v2 });
            }
        }, 1500);
        return () => clearInterval(interval);
    }, []);

    const markerSize = 15;

    return (
        <div style={{ width: "auto", position: "fixed", top: -2 }} className={styles.pointer}>
            <div style={{ width: "auto" }}>
                <div style={{ fontWeight: 400, width: 150, color: "#FFF", fontSize: 22, textAlign: "left" }}>
                    {props.hydropowerPlant.name}
                </div>
                <div style={{ maxWidth: 80 }}>
                    {!showData ?
                        <Tag style={{ marginTop: 0, marginRight: 0, width: "100%", textAlign: "center" }} color="#E74B5E">Offline</Tag> :
                        <Tag style={{ marginTop: 0, marginRight: 0, width: "100%", textAlign: "center" }}><AnimatedNumber component="span" stepPrecision={1} value={production}
                            style={{
                                transition: '0.8s ease-out',
                                fontSize: 14,
                                fontWeight: 900,
                                transitionProperty:
                                    'background-color, color, opacity'
                            }}
                            formatValue={(n) => n.toFixed(1)}
                            duration={800}
                        /><span style={{ fontWeight: 400, marginLeft: 6 }}>{productionDescription}</span></Tag>
                    }

                </div>
                <div style={{ height: 30, maxWidth: 80, paddingTop: 8 }}>
                    <Sparklines data={realtimeChartData} limit={20} margin={12}>
                        <SparklinesLine style={{ fill: "none", strokeWidth: 4 }} color="#4BB2E7" />
                        <SparklinesSpot style={{ stroke: "#4BB2E7", strokeWidth: 6, fill: "white" }} size={10} />
                    </Sparklines>
                </div>
            </div>
        </div>
    );
}

export default HydropowerPlantMarker;
