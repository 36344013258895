
import React, { ReactElement, useEffect, useState } from 'react';
import ReactMapGL, { Popup, } from 'react-map-gl';

// import EventsWidget from '../components/EventsWidget';
// import HydropowerPlantMarker from '../components/HydropowerPlantMarker';
// import HydropowerProductionWidget from '../components/HydropowerProductionWidget';
import { ActionTypes, IHydropowerPlant, useTracked } from '../../state';

import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Popover, Row } from 'antd';

import 'mapbox-gl/dist/mapbox-gl.css';

// @ts-ignore
import styles from './map.module.scss';
import HydropowerProductionWidget from '../HydropowerProductionWidget';
import EventsWidget from '../EventsWidget';
import HydropowerPlantMarker from '../HydropowerPlantMarker';

const { RangePicker } = DatePicker;
let initialLoad = true;

function Map() {
    const TOKEN = process.env.GATSBY_MAPBOX_TOKEN;
    const [state, dispatch] = useTracked();

    // Map
    const [map, setMap] = useState<any>();
    const [hydropowerPlantMarkers, setHydropowerPlantMarkers] = useState<ReactElement[]>([]);

    // @ts-ignore
    const [view, setView] = useState<IMapViewState>({});

    useEffect(() => {
        setView(state.mapViewState);
    }, [state.mapViewState]);


    useEffect(() => {
        const _d = state.hydropowerplants.map((hydropowerPlant: IHydropowerPlant) => {
            return (<Popup
                key={hydropowerPlant.id}
                latitude={hydropowerPlant.latitude}
                longitude={hydropowerPlant.longitude}
                closeButton={false}
                closeOnClick={false}
                captureClick={true}
                onClose={() => { }}
                anchor="left">
                <HydropowerPlantMarker hydropowerPlant={hydropowerPlant} />
            </Popup>);
        });
        setHydropowerPlantMarkers(_d);
    }, [state.hydropowerplants]);

    const handleMapEvent = (event) => {
        if (!event.isDragging && !event.iPanning && !event.isRotating && !event.isZooming) {
            if (view.latitude !== undefined) {
                // dispatch({ type: ActionTypes.MAP_POSITION_CHANGE, data: { latitude: view.latitude, longitude: view.longitude, zoom: view.zoom, pitch: view.pitch, bearing: view.bearing } });
            }
        }
    };

    const renderAccount = () => {

        const content = (
            <div>
                <Button
                    type="link"
                    icon={<LogoutOutlined />}
                >
                    Logg ut
                </Button>
            </div>
        );

        return (
            <Popover placement="topLeft" content={content} trigger="click">
                <Button
                    style={{ position: "absolute", zIndex: 999, marginTop: 24, right: 24, top: 0 }}
                    type="default"
                    shape="circle-outline"
                    icon={<UserOutlined />}
                    size="large" />
            </Popover>
        );
    };

    const renderWidgets = () => {
        return (
            <div style={{ bottom: 0, zIndex: 97, right: 0, left: 0, position: "absolute", padding: 6 }} className={styles.rowOverride}>
                <Row justify="center" gutter={[16, 16]} >
                    <Col xs={12} md={10}><HydropowerProductionWidget /></Col>
                    <Col xs={0} md={10}><EventsWidget /></Col>
                </Row>
            </div>
        );
    };

    return (<div className={styles.mapbox} style={{position: "absolute", top: 0, left: 0, right: 0, bottom: 0}}>
        <ReactMapGL
            className={styles.mapbox}
            {...view}
            width="100%"
            height="100%"
            scrollZoom={false}
            style={{borderRadius: 60}}
            mapStyle="mapbox://styles/bloever/ckah2my8e0r7j1imus961o353"
            mapboxApiAccessToken={TOKEN}
            onViewportChange={(viewport) => { setView(viewport); /*console.log(viewport)*/}}
            onInteractionStateChange={(event) => { handleMapEvent(event); }}
            onMouseEnter={(e) => { }}
            onLoad={(map) => {
                map.target.on('sourcedata', (map) => {
                    if (map.target.getSource('my-data') && map.target.isSourceLoaded('my-data') && initialLoad) {
                        initialLoad = false;
                    }
                });
                setMap(map.target);
            }}
        >
            {hydropowerPlantMarkers}
        </ReactMapGL>
        {renderWidgets()}
    </div>
    );
}

export default Map;
